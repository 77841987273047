const {
  ORDER_STATUS,
  AMOUNT_TYPE,
  WARRANTY_BILL_STATUS,
  PAYMENT_STATUS,
  DEBT_STOCK_STATUS,
  STOCK_HISTORY,
  STOCK_SLIP_TYPE,
  CUSTOMER_APPELLATION,
  JOB_TITLE,
  TRADE_IN_STATUS,
  TRADE_IN_TYPE,
  DEALSHEET_STATUS,
  DEALSHEET_TYPE,
  PRODUCT_STATUS,
  STOCK_SLIP_STATUS,
  STOCK_SLIP_RELATION_TYPE,
  TICKET_STATUS,
  TICKET_TYPE_STATUS,
  POINT_TRANSFER_STATUS,
  IS_ACTIVE,
  USER_TOKEN_DELETED_BY_ACTION,
  APPROVAL_TYPE_ENUM,
  VALUATION_REQUEST_STATUS,
  STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE,
} = require('./enum');

module.exports.ORDER_STATUS_NAME = Object.freeze({
  [ORDER_STATUS.NEW]: 'Mới',
  [ORDER_STATUS.CONFIRMING]: 'Đang xử lí',
  [ORDER_STATUS.CONFIRMED]: 'Đã xác nhận',
  [ORDER_STATUS.DELIVERY]: 'Đang giao',
  [ORDER_STATUS.PACKING]: 'Đang đóng gói',
  [ORDER_STATUS.SUCCESS]: 'Thành công',
  [ORDER_STATUS.UN_SUCCESS]: 'Không thành công',
  [ORDER_STATUS.SYSTEM_CANCEL]: 'Hệ thống hủy',
  [ORDER_STATUS.REFUNDED]: 'Đã chuyển hoàn',
  [ORDER_STATUS.NO_CATCHED_PHONE]: 'Không bắt máy',
  [ORDER_STATUS.CR_CANCEL]: 'CR huỷ',
  [ORDER_STATUS.DEPOSIT]: 'Đã đặt cọc',
  [ORDER_STATUS.AGREE_DEPOSIT]: 'Đồng ý cọc',
  [ORDER_STATUS.PREPARED]: 'Đã chuẩn bị',
  [ORDER_STATUS.TRACKING]: 'Đang theo dõi',
  [ORDER_STATUS.KEEP_DEPOSIT]: 'Giữ cọc', // IP14
});

module.exports.DEBT_STOCK_STATUS_NAME = Object.freeze({
  [DEBT_STOCK_STATUS.NEW]: 'Đang truy thu',
  [DEBT_STOCK_STATUS.DONE]: 'Hoàn thành',
});

module.exports.BILL_STATUS_NAME = Object.freeze({
  [WARRANTY_BILL_STATUS.NEW]: 'Mới',
  [WARRANTY_BILL_STATUS.PROCESSING]: 'Đang xử lý',
  [WARRANTY_BILL_STATUS.DONE]: 'Đã trả bảo hành',
});

module.exports.DISCOUNT_AMOUNT_TYPE_NAME = Object.freeze({
  [AMOUNT_TYPE.MONEY]: '$',
  [AMOUNT_TYPE.PERCENT]: '%',
});

module.exports.PAYMENT_STATUS_NAME = Object.freeze({
  [PAYMENT_STATUS.SUCCESS]: 'Đã thanh toán',
  [PAYMENT_STATUS.FAILED]: 'Chưa thanh toán',
});

module.exports.STOCK_HISTORY_NAME = Object.freeze({
  [STOCK_HISTORY.IMPORT_STOCK]: 'Nhập kho',
  [STOCK_HISTORY.EXPORT_STOCK]: 'Xuất kho',
  [STOCK_HISTORY.TRANSFER_STOCK]: 'Chuyển kho',
  [STOCK_HISTORY.UPDATE_IMPORT_STOCK]: 'Cập nhật phiếu nhập kho',
  [STOCK_HISTORY.UPDATE_EXPORT_STOCK]: 'Cập nhật phiếu xuất kho',
  [STOCK_HISTORY.EXPORT_TO_TRANSFER]: 'Xuất chuyển kho',
  [STOCK_HISTORY.IMPORT_FROM_TRANSFER]: 'Nhập chuyển kho',
  [STOCK_HISTORY.EXPORT_TO_SALE_RETAIL]: 'Xuất kho bán lẻ',
  [STOCK_HISTORY.EXPORT_TO_SALE_WHOLE_SASE]: 'Xuất kho bán buôn',
  [STOCK_HISTORY.IMPORT_FROM_RETURN_BILL]: 'Nhập từ phiếu trả hàng',
  [STOCK_HISTORY.IMPORT_FROM_REFUND_ORDER]: 'Nhập từ đơn chuyển hoàn',
  [STOCK_HISTORY.EXPORT_TO_SHIPPING]: 'Xuất kho giao hàng',
  [STOCK_HISTORY.INVENTORY_BALANCE]: 'Cân kho',
  [STOCK_HISTORY.IMPORT_WARRANTY]: 'Nhập kho bảo hành',
  [STOCK_HISTORY.UPDATE_IMPORT_WARRANTY]: 'Cập nhật nhập kho bảo hành',
  [STOCK_HISTORY.EXPORT_WARRANTY]: 'Xuất kho bảo hành',
  [STOCK_HISTORY.UPDATE_EXPORT_WARRANTY]: 'Cập nhật xuất kho bảo hành',
  [STOCK_HISTORY.CHANGE_PRODUCT_IMEI]: 'Đổi sản phẩm',
  [STOCK_HISTORY.REMOVE_FROM_BILL]: 'Xóa sản phẩm trong hóa đơn',
  [STOCK_HISTORY.REMOVE_BILL]: 'Xóa hóa đơn',
  [STOCK_HISTORY.REMOVE_RETURN_BILL]: 'Xóa hóa đơn trả hàng',
  [STOCK_HISTORY.UPDATE_EXPORT_TO_SALE]: 'Cập nhật hóa đơn',
  [STOCK_HISTORY.DELETE_TRANSFER_STOCK]: 'Xóa phiếu chuyển kho',
  [STOCK_HISTORY.DELETE_IMPORT_STOCK]: 'Xóa phiếu nhập',
  [STOCK_HISTORY.DELETE_EXPORT_STOCK]: 'Xóa phiếu xuất',
  [STOCK_HISTORY.IMPORT_FROM_TRADE_IN]: 'Nhập kho thu cũ',
  [STOCK_HISTORY.CANCEL_TRANSFER]: 'Hủy phiếu chuyển',
  [STOCK_HISTORY.IMPORT_HOLDING]: 'Nhập kho giữ hàng',
  [STOCK_HISTORY.UPDATE_IMPORT_HOLDING]: 'Cập nhật nhập kho giữ hàng',
  [STOCK_HISTORY.DELETE_IMPORT_HOLDING]: 'Xóa phiếu nhập kho giữ hàng',
  [STOCK_HISTORY.EXPORT_HOLDING]: 'Xuất trả giữ hàng',
  [STOCK_HISTORY.DELETE_EXPORT_HOLDING]: 'Xóa phiếu xuất trả giữ hàng',
  [STOCK_HISTORY.CANCEL_HOLDING]: 'Hủy phiếu giữ hàng',
  [STOCK_HISTORY.DELETE_IMPORT_WARRANTY]: 'Xoá phiếu nhập bảo hành',
  [STOCK_HISTORY.DELETE_EXPORT_WARRANTY]: 'Xoá phiếu xuất bảo hành',
  [STOCK_HISTORY.DELETE_IMPORT_TRADE_IN]: 'Xoá phiếu nhập thu cũ',
  [STOCK_HISTORY.IMPORT_WARRANTY_FROM_BILL]: 'Nhập kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.EXPORT_WARRANTY_FROM_BILL]: 'Xuất kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.UPDATE_IMPORT_WARRANTY_FROM_BILL]:
    'Cập nhật nhập kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.UPDATE_EXPORT_WARRANTY_FROM_BILL]:
    'Cập nhật xuất kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.DELETE_IMPORT_WARRANTY_FROM_BILL]:
    'Xóa phiếu nhập kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.DELETE_EXPORT_WARRANTY_FROM_BILL]:
    'Xóa phiếu xuất kho bảo hành từ hóa đơn',
  [STOCK_HISTORY.EXPORT_STOCK_BY_WARRANTY_BILL]:
    'Xuất kho bởi đổi sp từ hóa đơn bảo hành',
  [STOCK_HISTORY.DELETE_REQUIRE_EXPORT_WARRANTY_STOCK]:
    'Xóa phiếu yêu cầu xuất nhập kho bảo hành',
  [STOCK_HISTORY.EXPORT_WARRANTY_TO_PROVIDER]: 'Xuất kho trả nhà cung cấp',
  [STOCK_HISTORY.UPDATE_EXPORT_WARRANTY_TO_PROVIDER]:
    'Xập nhật xuất kho trả nhà cung cấp',
  [STOCK_HISTORY.DELETE_EXPORT_WARRANTY_TO_PROVIDER]:
    'Xoá phiếu xuất kho trả nhà cung cấp',
  [STOCK_HISTORY.IMPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Nhập kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.EXPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Xuất kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.CREATE_WARRANTY_COMPLETION_SLIP]:
    'Tạo phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.DELETE_WARRANTY_COMPLETION_SLIP]:
    'Xoá phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.DELETE_IMPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Xoá phiếu nhập kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.DELETE_EXPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Xoá phiếu xuất kho bởi phiếu hoàn tất bảo hành',
  [STOCK_HISTORY.EXPORT_WARRANTY_STOCK_BY_DELETE_WARRANTY_BILL]:
    'Xuất kho bảo hành bởi xoá hoá đơn bảo hành',
  [STOCK_HISTORY.IMPORT_STOCK_BY_DELETE_WARRANTY_BILL]:
    'Nhập kho bởi xoá hoá đơn bảo hành',
  [STOCK_HISTORY.IMPORT_STOCK_BY_DELETE_STORE_WARRANTY_BILL]:
    'Nhập kho bởi xoá hoá đơn bảo hành (lỗi cửa hàng)',
  [STOCK_HISTORY.EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL]:
    'Xuất kho bởi tạo hoá đơn bảo hành (lỗi cửa hàng)',
  [STOCK_HISTORY.DELETE_EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL]:
    'Nhập kho bởi xoá hoá đơn bảo hành (lỗi cửa hàng)',
  [STOCK_HISTORY.EXPORT_LENDING]: 'Nhập kho mượn máy',
  [STOCK_HISTORY.UPDATE_EXPORT_LENDING]: 'Cập nhật phiếu xuất kho cho mượn máy',
  [STOCK_HISTORY.DELETE_EXPORT_LENDING]: 'Xoá phiếu xuất kho cho mượn máy',
  [STOCK_HISTORY.IMPORT_STOCK_LENDING]: 'Nhập kho trả máy',
});

module.exports.STOCK_SLIP_NAME = Object.freeze({
  [STOCK_SLIP_TYPE.IMPORT_PROVIDER]: 'Nhập kho NCC',
  [STOCK_SLIP_TYPE.EXPORT_PROVIDER]: 'Xuất kho NCC',
  [STOCK_SLIP_TYPE.TRANSFER_STOCK]: 'Chuyển kho',
  [STOCK_SLIP_TYPE.REQUIRE_IMPORT_STOCK]: 'Yêu cầu nhập chuyển kho',
  [STOCK_SLIP_TYPE.REQUIRE_EXPORT_STOCK]: 'Yêu cầu xuất chuyển kho',
  [STOCK_SLIP_TYPE.EXPORT_STOCK_TO_SALE]: 'Xuất kho bán lẻ',
  [STOCK_SLIP_TYPE.EXPORT_STOCK_TO_SALE_WHOLSE]: 'Xuất kho bán buôn',
  [STOCK_SLIP_TYPE.IMPORT_STOCK_FROM_BILL]: 'Nhập kho từ phiếu trả hàng',
  [STOCK_SLIP_TYPE.IMPORT_STOCK_FROM_ORDER]: 'Nhập kho từ đơn chuyển hoàn',
  [STOCK_SLIP_TYPE.EXPORT_TO_SHIPPING]: 'Xuất kho giao hàng',
  [STOCK_SLIP_TYPE.IMPORT_FROM_TRADE_IN]: 'Nhập kho từ phiếu thu cũ',
  [STOCK_SLIP_TYPE.TRANSFER_TO_NHANH]: 'Chuyển kho sang hệ thống cũ',
  [STOCK_SLIP_TYPE.REQUIRE_EXPORT_STOCK_TO_NHANH]:
    'Phiếu yêu cầu xuất chuyển kho sang hệ thống cũ',
  [STOCK_SLIP_TYPE.EXPORT_TRANSFER_TO_NHANH]:
    'Xuất chuyển kho sang hệ thống cũ',
  [STOCK_SLIP_TYPE.IMPORT_WARRANTY]: 'Nhập kho bảo hành',
  [STOCK_SLIP_TYPE.EXPORT_WARRANTY]: 'Xuất kho bảo hành',
  [STOCK_SLIP_TYPE.IMPORT_HOLDING]: 'Nhập giữ hàng',
  [STOCK_SLIP_TYPE.EXPORT_HOLDING]: 'Xuất trả giữ hàng',
  [STOCK_SLIP_TYPE.TRANSFER_WARRANTY_STOCK]: 'Chuyển kho bảo hành',
  [STOCK_SLIP_TYPE.REQUIRE_EXPORT_WARRANTY_STOCK]:
    'Yêu cầu xuất chuyển kho bảo hành',
  [STOCK_SLIP_TYPE.IMPORT_STOCK_FROM_WARRANTY_BILL]:
    'Nhập kho từ phiếu bảo hành',
  [STOCK_SLIP_TYPE.IMPORT_WARRANTY_FROM_BILL]:
    'Nhập kho bảo hành từ hóa đơn bảo hành',
  [STOCK_SLIP_TYPE.EXPORT_WARRANTY_FROM_BILL]:
    'Xuất kho bảo hành từ hóa đơn bảo hành',
  [STOCK_SLIP_TYPE.EXPORT_STOCK_BY_WARRANTY_BILL]:
    'Xuất kho bởi trả khác mã từ hóa đơn bảo hành',
  [STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER]:
    'Xuất kho bảo hành trả nhà cung cấp',
  [STOCK_SLIP_TYPE.UPDATE_EXPORT_WARRANTY_TO_PROVIDER]:
    'Cập nhật phiếu xuất kho bảo hành trả nhà cung cấp',
  [STOCK_SLIP_TYPE.DELETE_EXPORT_WARRANTY_TO_PROVIDER]:
    'Xóa phiếu xuất kho bảo hành trả nhà cung cấp',
  [STOCK_SLIP_TYPE.CREATE_WARRANTY_COMPLETION_SLIP]:
    'Tạo phiếu hoàn tất bảo hành',
  [STOCK_SLIP_TYPE.DELETE_WARRANTY_COMPLETION_SLIP]:
    'Xoá phiếu hoàn tất bảo hành',
  [STOCK_SLIP_TYPE.IMPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Nhập kho bởi phiếu hoàn tất bảo hành',
  [STOCK_SLIP_TYPE.EXPORT_STOCK_BY_WARRANTY_COMPLETION]:
    'Xuất kho bởi phiếu hoàn tất bảo hành',
  [STOCK_SLIP_TYPE.EXPORT_WARRANTY_STOCK_BY_DELETE_WARRANTY_BILL]:
    'Xuất kho bảo hành bởi bởi xoá hoá đơn bảo hành',
  [STOCK_SLIP_TYPE.IMPORT_STOCK_BY_DELETE_WARRANTY_BILL]:
    'Nhập kho bởi bởi xoá hoá đơn bảo hành',
  [STOCK_SLIP_TYPE.IMPORT_STOCK_BY_DELETE_STORE_WARRANTY_BILL]:
    'Nhập kho bởi bởi xoá hoá đơn bảo hành (lỗi cửa hàng)',
  [STOCK_SLIP_TYPE.EXPORT_STOCK_BY_CREATE_STORE_WARRANTY_BILL]:
    'Xuất kho bởi tạo hoá đơn bảo hành (lỗi cửa hàng)',
});

module.exports.CUSTOMER_APPELLATION_NAME = Object.freeze({
  [CUSTOMER_APPELLATION.MR]: 'Anh',
  [CUSTOMER_APPELLATION.MS]: 'Chị',
});

module.exports.JOB_TITLE_NAME = Object.freeze({
  [JOB_TITLE.GD]: 'Giám đốc',
  [JOB_TITLE.RSM]: 'Quản lý vùng',
  [JOB_TITLE.ASM]: 'Quản lý khu vực',
  [JOB_TITLE.QL]: 'Quản lý cửa hàng',
  [JOB_TITLE.TL]: 'Trợ lý cửa hàng',
  [JOB_TITLE.KTBH]: 'Kế toán bán hàng',
  [JOB_TITLE.TVBH]: 'Tư vấn bán hàng',
  [JOB_TITLE.KTPM]: 'Hỗ trợ kỹ thuật',
  [JOB_TITLE.TDKH]: 'Tiếp đón khách hàng',
  [JOB_TITLE.KTPC]: 'Kỹ thuật phần cứng',
  [JOB_TITLE.GDNHPK]: 'Giám đốc ngành hàng phụ kiện',
  [JOB_TITLE.NVNHPK]: 'Nhân viên phụ trách ngành hàng phụ kiện',
  [JOB_TITLE.ADMIN_NHPK]: 'Admin ngành hàng phụ kiện',
  [JOB_TITLE.CDPK]: 'Nhân viên chuyên doanh phụ kiện',
  [JOB_TITLE.QL_CSKH]: 'Quản lý CR',
  [JOB_TITLE.CSKH]: 'Nhân viên CR',
  [JOB_TITLE.CTV_CSKH]: 'Cộng tác viên CR',
  [JOB_TITLE.ADMIN_GDNHPK]: 'Admin giám đốc ngành hàng phụ kiện',
  [JOB_TITLE.KTNB]: 'Kế toán nội bộ',
  [JOB_TITLE.QL_DP]: 'Trưởng bộ phận điều phối',
  [JOB_TITLE.MKT]: 'Nhân viên Marketing',
  [JOB_TITLE.QL_MKT]: 'Trưởng Marketing',
  [JOB_TITLE.DP]: 'Điều phối',
  [JOB_TITLE.QL_KTNB]: 'Trưởng kế toán nội bộ',
  [JOB_TITLE.NV_QA]: 'Nhân viên kiểm định chất lượng (Kiểm soát/QA)',
  [JOB_TITLE.HV_QL]: 'Học việc quản lý',
  [JOB_TITLE.HV_TL]: 'Học việc trợ lý',
  [JOB_TITLE.ADMIN_MKT]: 'Marketing Admin',
  [JOB_TITLE.TRADE_MKT]: 'Trade Marketing',
  [JOB_TITLE.TRADE_MKT_MANAGER]: 'Trade Marketing Manager',
  [JOB_TITLE.CR_LEADER_LQD]: 'Team leader CR LQĐ',
  [JOB_TITLE.CR_LEADER_TQK]: 'Team leader CR TQK',
});

module.exports.TRADE_IN_STATUS_NAME = Object.freeze({
  [TRADE_IN_STATUS.QUOTE]: 'Đã báo giá',
  [TRADE_IN_STATUS.COLLECTED]: 'Đã Thu cũ',
});

module.exports.TRADE_IN_TYPE_NAME = Object.freeze({
  [TRADE_IN_TYPE.TRADE_IN]: 'Khách thu cũ đổi mới',
  [TRADE_IN_TYPE.BUYING_OLD_ITEMS]: 'Khách chỉ bán lại máy',
  [TRADE_IN_TYPE.EXCHANGE_WARRANTY]: 'Khách đổi bảo hành',
});

module.exports.DEALSHEET_STATUS_NAME = Object.freeze({
  [DEALSHEET_STATUS.ACTIVE]: 'Hoạt động',
  [DEALSHEET_STATUS.INACTIVE]: 'Đã ngừng',
});

module.exports.DEALSHEET_TYPE_NAME = Object.freeze({
  [DEALSHEET_TYPE.HOT_SALE]: 'Hot sale',
  [DEALSHEET_TYPE.JBP]: 'JBP',
});

module.exports.PRODUCT_STATUS_NAME = Object.freeze({
  [PRODUCT_STATUS.NEW]: 'Mới',
  [PRODUCT_STATUS.SELLING]: 'Đang bán',
  [PRODUCT_STATUS.STOP_SELLING]: 'Ngừng bán',
  [PRODUCT_STATUS.OUT_OF_STOCK]: 'Hết hàng',
});

module.exports.STOCK_SLIP_STATUS_NAME = Object.freeze({
  [STOCK_SLIP_STATUS.NEW]: 'Mới',
  [STOCK_SLIP_STATUS.APPROVED]: 'Đã duyệt',
  [STOCK_SLIP_STATUS.CONFIRMED]: 'Đã xác nhận',
  [STOCK_SLIP_STATUS.CANCELD]: 'Đã huỷ',
});

module.exports.STOCK_SLIP_RELATION_TYPE_NAME = Object.freeze({
  [STOCK_SLIP_RELATION_TYPE.INDEPENDENT]: 'Phiếu độc lập',
  [STOCK_SLIP_RELATION_TYPE.PARENT]: 'Phiếu tổng',
  [STOCK_SLIP_RELATION_TYPE.CHILD]: 'Phiếu con',
});

module.exports.TICKET_STATUS_NAME = Object.freeze({
  [TICKET_STATUS.NEW]: 'Mới',
  [TICKET_STATUS.RECORDED_INFOMATION]: 'Ghi nhận thông tin',
  [TICKET_STATUS.PROCESSING]: 'Đang xử lí',
  [TICKET_STATUS.COMPLETED]: 'Hoàn thành',
  [TICKET_STATUS.REJECT]: 'Từ chối',
});

module.exports.TICKET_TYPE_STATUS_NAME = Object.freeze({
  [TICKET_TYPE_STATUS.ON]: 'Bật',
  [TICKET_TYPE_STATUS.OFF]: 'Tắt',
});

module.exports.POINT_TRANSFER_STATUS_NAME = Object.freeze({
  [POINT_TRANSFER_STATUS.NEW]: 'Mới',
  [POINT_TRANSFER_STATUS.COMPLETED]: 'Đã duyệt',
  [POINT_TRANSFER_STATUS.REJECT]: 'Từ chối',
});

module.exports.STOCK_SLIP_STATUS_NAME = Object.freeze({
  [STOCK_SLIP_STATUS.NEW]: 'Mới',
  [STOCK_SLIP_STATUS.APPROVED]: 'Đã duyệt',
  [STOCK_SLIP_STATUS.CONFIRMED]: 'Đã xác nhận',
  [STOCK_SLIP_STATUS.CANCELD]: 'Đã huỷ',
});

module.exports.STOCK_SLIP_RELATION_TYPE_NAME = Object.freeze({
  [STOCK_SLIP_RELATION_TYPE.INDEPENDENT]: 'Phiếu độc lập',
  [STOCK_SLIP_RELATION_TYPE.PARENT]: 'Phiếu tổng',
  [STOCK_SLIP_RELATION_TYPE.CHILD]: 'Phiếu con',
});

module.exports.IS_ACTIVE_NAME = Object.freeze({
  [IS_ACTIVE.TRUE]: 'Hoạt động',
  [IS_ACTIVE.FALSE]: 'Đã ngừng',
});

module.exports.USER_TOKEN_DELETED_BY_ACTION_NAME = Object.freeze({
  [USER_TOKEN_DELETED_BY_ACTION.TOKEN_IS_ACTIVE]: 'Token đang hoạt động',
  [USER_TOKEN_DELETED_BY_ACTION.CHANGE_PASSWORD]: 'Đổi mật khẩu',
  [USER_TOKEN_DELETED_BY_ACTION.RESET_PASSWORD]: 'Reset mật khẩu',
  [USER_TOKEN_DELETED_BY_ACTION.RESTORE_PASSWORD]: 'Khôi phục mật khẩu',
  [USER_TOKEN_DELETED_BY_ACTION.IN_ACTIVE_USER]: 'Khoá tài khoản',
  [USER_TOKEN_DELETED_BY_ACTION.UPDATE_ROLE]: 'Cập nhật quyền',
  [USER_TOKEN_DELETED_BY_ACTION.UPDATE_INFO_USER]:
    'Cập nhật thông tin tài khoản',
  [USER_TOKEN_DELETED_BY_ACTION.RATE_LIMIT]: 'Rate limit',
  [USER_TOKEN_DELETED_BY_ACTION.REVOKE_TOKEN]: 'Thu hồi token',
});

module.exports.APPROVAL_TYPE_NAME = Object.freeze({
  [APPROVAL_TYPE_ENUM.OVER_PAYMENT]: 'Thanh toán dư',
  [APPROVAL_TYPE_ENUM.BUY_OLD]: 'Thu cũ',
  [APPROVAL_TYPE_ENUM.REFUND]: 'Trả hàng',
  [APPROVAL_TYPE_ENUM.TRADE_IN]: 'Thu cũ- đổi mới',
  [APPROVAL_TYPE_ENUM.VAT]: 'Xuất VAT',
  [APPROVAL_TYPE_ENUM.DEPOSIT]: 'Hoàn cọc',
});

module.exports.VALUATION_REQUEST_STATUS_NAME = Object.freeze({
  [VALUATION_REQUEST_STATUS.NEW]: 'Mới',
  [VALUATION_REQUEST_STATUS.PROCESSING]: 'Đang xử lí',
  [VALUATION_REQUEST_STATUS.CONFIRMED]: 'Đã xác nhận',
  [VALUATION_REQUEST_STATUS.SUCCESS]: 'Thành công',
  [VALUATION_REQUEST_STATUS.NO_CONTACT]: 'Không liên hệ được KH',
  [VALUATION_REQUEST_STATUS.CANCEL]: 'Hủy',
});

module.exports.STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE_NAME = Object.freeze({
  [STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE.SCAN_QR_CODE]: 'Scan QR',
  [STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE.MOBILE_MANUAL]: 'Thủ công mobile',
  [STOCK_CHECK_PRODUCT_LOG_SOURCE_TYPE.WEB_MANUAL]: 'Thủ công từ web',
});
